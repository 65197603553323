import React, { useContext, useEffect, useState } from "react";

//packages
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";

//contest
import { Context } from "../../Contexts/Store";

// components
// import HumbergModal from "../../modal/HumbergModal";

function Header() {
    //for making buttons active---------
    const [active, setActive] = useState("/");
    const loc = useLocation();
    const location = useLocation();
    const navigate = useNavigate();

    //hamburger functions-----
    const [HamburgerState, setHamburgerState] = useState(false);

    //state(store)
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    useEffect(() => {
        dispatchFunction();
    }, [loc]);

    //logout routes
    const logoutRoutes = {
        prebook: "/pre-book",
    };

    //logout function
    const logout = () => {
        dispatch({
            type: "LOGOUT",
            user_data: {},
        });
        navigate("/");
    };

    //to active set correct path
    const firstPath = window.location.pathname.split("/")[1];

    const activeData = user_data.active_item;

    const dispatchFunction = () => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_item: loc.pathname,
            },
        });
    };

    const handleHamberger = () => {
        setHamburgerState(!HamburgerState);
    };

    //preventing outside scrolling
    useEffect(() => {
        if (HamburgerState) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [HamburgerState]);

    //header transition function
    // var prevScrollpos = window.pageYOffset;
    // const handleScroll = () => {
    //     var currentScrollPos = window.pageYOffset;
    //     const navbar = document.getElementById("navbar");
    //     if (navbar) {
    //         if (prevScrollpos <= 30) {
    //             navbar.style.top = "0";
    //             navbar.style.transition = "all 0.8s";
    //         } else {
    //             navbar.style.top = "-200px";
    //             navbar.style.transition = "all 0.8s";
    //         }
    //         prevScrollpos = currentScrollPos;
    //         setInterval(() => {
    //             navbar.style.top = "0";
    //             navbar.style.transition = "all 0.8s";
    //         }, 1000);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    //scroll to top function
    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <HeaderContainer id="navbar">
                <NavBar>
                    <Wrapper>
                        <DivLeft>
                            <LogoContainer>
                                <Logo
                                    href="https://www.dett.app"
                                    target="_blank"
                                    // onClick={(e) => {
                                    //     setActive("/");
                                    //     scrollToTop(e);
                                    // }}
                                    className={activeData === "/" && "active"}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/dett-logo.svg")
                                                .default
                                        }
                                        alt="header-logo"
                                    />
                                </Logo>
                            </LogoContainer>
                        </DivLeft>
                        <Middle>
                        </Middle>
                        {/* <Account>
                            <Pages>
                                {location.pathname === "/womens-day-form" ? (<div></div>) : (location.pathname ===
                                logoutRoutes[location.pathname.slice(1).replace("-", "")] ? (
                                    <LogoutButton>
                                        <span onClick={() => logout()}>Log Out</span>
                                    </LogoutButton>
                                ) : (
                                    <>
                                        <Page
                                            // to="gift"
                                            // onClick={() => {
                                            //   setActive("gift");
                                            // }}
                                            // className={activeData === "gift" && "active"}
                                            to="/"
                                            onClick={() => {
                                                setActive("/");
                                            }}
                                            className={firstPath === "" && "active"}
                                        >
                                            <span>Home</span>
                                        </Page>
                                        <Page
                                            to="about"
                                            onClick={() => {
                                                setActive("about");
                                            }}
                                            className={firstPath === "about" && "active"}
                                        >
                                            <span>About Us</span>
                                        </Page>
                                    </>
                                ))}
                            </Pages>
                        </Account> */}
                        {/* <HumburgContainer
                            onClick={() => handleHamberger()}
                            className={HamburgerState && "change-color"}
                            // onClick={() => setHamburgerState(!HamburgerState)}
                        >
                            <HumburgImage
                                src={
                                    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/humburg.svg"
                                }
                                alt="humburger"
                            />
                        </HumburgContainer> */}
                        {/* <HumbergModal
                            HamburgerState={HamburgerState}
                            setHamburgerState={setHamburgerState}
                        /> */}
                    </Wrapper>
                </NavBar>
            </HeaderContainer>
            <Outlet />
        </>
    );
}

const HeaderContainer = styled.header`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* box-shadow: rgb(0 0 0 / 3%) 0px 10px 24px; */
    border-bottom: 1px solid #eeeeee;
    background-color: rgb(255 255 255);

    backdrop-filter: blur(10px);
    /* padding: 4px 0; */
    transition: all 0.8s ease 0s;
    transform-origin: top;
    z-index: 10;

    &.show {
        transform: scaleY(1);
        z-index: 10;
    }
    &.hide {
        transform: scaleY(0);
        z-index: 0;
    }
    &.header-show {
        transform: scaleY(1);
        box-shadow: none;
        background-color: transparent;
        backdrop-filter: none;
        z-index: 10;
        padding: 0;
        & .wrapper {
            box-shadow: rgb(0 0 0 / 3%) 0px 16px 24px;
            background-color: rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(10px);
            justify-content: flex-end;
            padding: 10px 20px;
            border-radius: 0 0 10px 10px;
            & .hamburger {
                display: block;
            }
        }
    }
    &.header-hide {
        transform: scaleY(0);
        z-index: 0;
    }

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @media all and (max-width: 768px) {
        /* padding: 16px 0; */
    }
`;
const NavBar = styled.section`
    width: 100%;
    /* height: 70px; */
    /* border-bottom: 1px solid #eeeeee; */
    display: flex;
    @media all and (max-width: 480px) {
        height: 65px;
    }
`;
const Wrapper = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    padding: 10px 0;
    @media all and (max-width: 768px) {
    }

    @media all and (max-width: 360px) {
        width: 85%;
    }
`;

const LogoContainer = styled.h1`
    /* width: 24%; */

    @media all and (max-width: 980px) {
        width: 45%;
    }

    @media all and (max-width: 768px) {
        width: 50%;
    }

    @media all and (max-width: 640px) {
        width: 40%;
    }

    @media all and (max-width: 550px) {
        width: 45%;
    }
    @media all and (max-width: 480px) {
        width: 40%;
    }

    @media all and (max-width: 360px) {
        width: 50%;
    }
`;

const Logo = styled.a`
    width: 80px;
    /* width: 100px; */
    display: inline-block;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 1080px) {
        width: 70px;
    }

    @media all and (max-width: 980px) {
        width: 62px;
    }

    @media all and (max-width: 768px) {
        width: 60px;
    }

    @media all and (max-width: 640px) {
        width: 55px;
    }
    @media all and (max-width: 480px) {
        /* width: 50px; */
    }

    @media all and (max-width: 360px) {
        /* width: 45px; */
    }
`;

const Middle = styled.div`
    display: flex;
`;
const Pages = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;

    @media all and (max-width: 980px) {
        display: none;
    }
`;

const LogoutButton = styled.span`
    border-radius: 8px;
    border: 1px solid #ff7d6c;
    padding: 10px 40px;
    font-size: 18px;
    font-family: "dm_sansboldmedium";
    color: #ff7d6c;
    cursor: pointer;
`;
const Page = styled(Link)`
    margin-right: 20px;

    :last-child {
        margin-right: 0;
    }
    &.active {
        span {
            font-family: "dm_sansboldmedium";
            font-size: 16px;
            color: #ff5e48;
            :hover {
                color: #ff5e48;
            }
            @media all and (max-width: 1080px) {
                font-size: 13px;
            }
        }
    }
    span {
        font-family: "dm_sansboldmedium";
        font-size: 16px;
        :hover {
            color: #ff5e48;
        }
        @media all and (max-width: 1080px) {
            font-size: 13px;
        }
    }
`;
const Account = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 20px;
    /* @media all and (max-width: 1255px) {
        width: 20.5%;
    }
    @media all and (max-width: 1000px) {
        width: 23%;
    }
    @media all and (max-width: 768px) {
        width: 25%;
    }
    @media all and (max-width: 480px) {
        width: 34%;
    } */
`;

const Profile = styled(NavLink)`
    align-items: center;
    text-align: center;

    &.active {
        & a {
            color: #ff5e48;
        }
        & .icon {
            display: none;
        }
        & .responsive {
            display: block;
        }
    }
`;
const TextColor = styled.a`
    font-family: "dm_sansboldbold";
    font-size: 14px;
    color: #000;

    @media all and (max-width: 1080px) {
        font-size: 13px;
    }
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const Icon = styled.div`
    width: 18px;
    margin: 0 auto;

    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        width: 24px;
    }
    .icon {
        @media all and (max-width: 980px) {
            display: none;
        }
    }
`;
const Active = styled.div`
    width: 18px;
    margin: 0 auto;
    display: none;
    &.active {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        width: 24px;
        .icon {
            display: none;
        }
    }
`;
const Wishlist = styled(Link)`
    /* a {
    font-family: "dm_sansboldbold";
    font-size: 14px;

    @media all and (max-width: 980px) {
      font-size: 14px;
    }
    @media all and (max-width: 480px) {
      display: none;
    }
  } */
`;
const Cart = styled(Link)`
    /* a {
    font-family: "dm_sansboldbold";
    font-size: 14px;

    @media all and (max-width: 980px) {
      font-size: 14px;
    }
    @media all and (max-width: 480px) {
      display: none;
    } */
`;
const Reseller = styled(Link)`
    color: #ff5e48;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #ff5e48;
    padding: 13px 20px;
    border-radius: 8px;
    font-family: "dm_sansboldbold";
    @media all and (max-width: 1080px) {
        padding: 13px;
    }
    @media all and (max-width: 1090px) {
        padding: 10px;
    }
    @media all and (max-width: 980px) {
        display: none;
    }
`;

const TalropLogoContainer = styled.a`
    display: inline-block;
    @media all and (max-width: 980px) {
        margin-right: 20px;
    }
`;
const TalropLogo = styled.img`
    width: 100%;
    display: block;
`;
const HumburgContainer = styled.div`
    display: none;
    &:hover {
        filter: invert(60%) sepia(60%) saturate(4380%) hue-rotate(329deg) brightness(100%)
            contrast(115%);
    }
    &.change-color {
        filter: invert(60%) sepia(60%) saturate(4380%) hue-rotate(329deg) brightness(100%)
            contrast(115%);
    }
    @media all and (max-width: 980px) {
        display: block;
        width: 40px;
    }
    @media all and (max-width: 480px) {
        width: 25px;
    }
`;
const HumburgImage = styled.img`
    width: 100%;
    display: block;
`;
const DivLeft = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    /* width: 38%; */

    @media all and (max-width: 980px) {
        width: 24%;
    }
    @media all and (max-width: 640px) {
        width: 35%;
    }
    @media all and (max-width: 480px) {
        width: 40%;
    }
`;

export default Header;
