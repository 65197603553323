import React from "react";

//json files
import loaderAnimation from "../../assets/lottie/gift.json";

//packages
import styled from "styled-components";
import Lottie from "react-lottie";

export default function GiftLottie() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {},
    };

    return (
        <Container>
            <Lottie
                options={defaultOptions}
                width={240}
            />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    /* height: 40vh; */
    margin: 0 auto;
    /* min-height: calc(100vh - 300px); */
`;
