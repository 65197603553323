const Reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      const user_data = { ...state.user_data, ...action.user_data };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      return {
        ...state,
        user_data: user_data,
      };
    case "UPDATE_SEARCH_TEXT":
        return {
        ...state,
          searchedText: action.searchedText,
    };
	case "UPDATE_LOADED_STATE":
		return {
		...state,
		loaded: action.loaded,
	};
	case "UPDATE_VERIFIED_STATE":
		return {
		...state,
		isVerified: action.isVerified,
	};
	case "LOGOUT":
		return {
		...state,
		user_data: action.user_data,
	}
   
    default:
      return state;
  }
};

export default Reducer;
