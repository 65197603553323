import React, { useContext, useEffect } from "react";
import AppRouter from "./AppRouter";
import {  Routes, Route } from "react-router-dom";
import { Context } from "../../Contexts/Store";

export default function MainRouter() {
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });

  }, []);
  return (
    <Routes>
      <Route path="/*" element={<AppRouter />}/>
    </Routes>
  );
}
