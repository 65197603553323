import React from "react";

//packages
import styled from "styled-components";
import { Link } from "react-router-dom";

//components
import GiftLottie from "../general/GiftLottie";

function SuccessModal({ setSuccessModal, successModal }) {
    return (
        <MainContainer>
            {successModal ? <Overlay onClick={() => setSuccessModal(false)}></Overlay> : ""}
            <BackContainer style={{ transform: successModal && "scale(1,1)" }}>
                <Modal type="textarea">
                    <Container>
                        <Top>
                            <LottieContainer>
                                <GiftLottie />
                            </LottieContainer>
                        </Top>
                        <Bottom>
                            <Paragraph>
                                Your Occasion Has Been <br /> Added Successfully!
                            </Paragraph>
                        </Bottom>
                        <CloseBox onClick={() => setSuccessModal(false)}>
                            <img
                                src={require("../../assets/images/close.svg").default}
                                alt="close"
                            />
                        </CloseBox>
                    </Container>
                </Modal>
            </BackContainer>
        </MainContainer>
    );
}

export default SuccessModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    margin: 0 auto;
    right: 0;
    z-index: 1000;
    left: 0;
    top: 50%;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;

const MainContainer = styled.div``;

const CloseBox = styled.div`
    position: absolute;
    right: 10px;
    top: 7px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    padding: 3px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 10;
`;

const Modal = styled.div`
    width: 50%;
    position: relative;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    z-index: 101;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;

    @media all and (max-width: 1150px) {
        width: 40%;
    }

    @media all and (max-width: 980px) {
        width: 50%;
    }

    @media all and (max-width: 768px) {
        width: 55%;
    }

    @media all and (max-width: 640px) {
        width: 75%;
    }

    @media all and (max-width: 480px) {
        width: 88%;
    }

    @media all and (max-width: 360px) {
        width: 95%;
    }
`;

const Container = styled.div`
    width: 80%;
    position: relative;
    background: #fff;
    border-radius: 15px;
    margin: 0 auto;
    padding: 40px 0px;
    @media all and (max-width: 980px) {
        width: 90%;
    }
`;

const Top = styled.div``;

const LottieContainer = styled.div``;

const Bottom = styled.div`
    text-align: center;
`;

const Paragraph = styled.p`
    font-size: 18px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 30px;
`;

const ButtonContainer = styled.a`
    width: 50%;
    margin: 0 auto;
    height: 45px;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-family: "dm_sansboldmedium";
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media all and (max-width: 480px) {
        width: 70%;
        font-size: 14px;
    }
`;

const CancelButton = styled(Link)`
    text-decoration: underline;
    display: inline-block;
    color: #777a86;
    font-size: 14px;
    margin-top: 15px;
    cursor: pointer;
`;
