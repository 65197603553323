import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

// const initialState = {
//   user_data: {
//     phone: "",
//     access_token: "",
//     is_exists: false,
//     phone_code: "",
//     flag: "",
//     country_code: "",
//     phone_number_length: 10,
//     counter: 30,
//   },
//   user_details: {
//     name: "",
//     dob: "",
//     token: {},
//   },
// };


const initialState = {
  user_data: {
    active_item: "",
    phone: "",
    access_token: "",
    is_exists: false,
    logged_in : false,
    phone_code: "",
    flag: "",
    country_code: "",
    phone_number_length: 10,
    counter: 30,
  },
  user_details: {
    name: "",
    dob: "",
    token: {},
  },
  searchedText : "",
  loaded: false,
  isVerified : "pending",
};
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;


// import React, { createContext, useReducer } from "react";
// import Reducer from "./Reducer";

// const initialState = {
//   user_data: {
//     phone: "",
//     access_token: "",
//     is_exists: false,
//     phone_code: "",
//     flag: "",
//     country_code: "",
//     phone_number_length: 10,
//     counter: 30,
//   },
//   user_details: {
//     name: "",
//     dob: "",
//     token: {},
//   },
// };
// const Store = ({ children }) => {
//   const [state, dispatch] = useReducer(Reducer, initialState);
//   return (
//     <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
//   );
// };

// export const Context = createContext(initialState);

// export default Store;