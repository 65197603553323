import React, { useState, Suspense, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";


import { Context } from "../../Contexts/Store";
import Loader from "../../general/loader/Loader";
import TemporaryClosing from "../../screens/TemporaryClosing";
import WomensDayForm from "../../screens/WomensDayForm";

function AppRouter() {
  const {
    state: { user_data },
  } = useContext(Context);
  const isUser = user_data?.is_exists;
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
          {/* <Route path="/" element={<WomensDayForm />} /> */}
          <Route path="/" element={<TemporaryClosing />} />
      </Routes>
    </Suspense>
  );
}

export default AppRouter;
