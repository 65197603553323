import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Highlight from "react-highlight-words";
import $ from "jquery";
import moment from "moment";
import { dettConfig } from "../../axiosConfig";
import DropDownLoader from "../general/loader/DropDownLoader";
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";
import CalendarModal from "../modal/CalenderModal";
import SuccessModal from "../modal/SuccessModal";

export default function WomensDayForm() {
    //setting input fields
    const [senderName, setSenderName] = useState("");
    const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [receipentName, setReceipentName] = useState("");
    const [receipentWhatsappNo, setReceipentWhatsappNo] = useState("");
    const [receipentContactNo, setReceipentContactNo] = useState("");
    const [houseName, setHouseName] = useState("");
    const [streetName, setStreetName] = useState("");
    const [townName, setTownName] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [landMark, setLandMark] = useState("");

    //setting mobile screen
    const [isMobileScreen, setMobileScreen] = useState(false);

    //setting relation list
    const [relation, setRelation] = useState("");
    const [relationList, setRelationList] = useState([]);

    const [relationLoader, setrelationLoader] = useState(false);
    const [relationSuggestions, setRelationSuggestions] = useState(false);

    //setting districts list
    const [district, setDistrict] = useState("");
    const [districtID, setDistrictID] = useState("");
    const [districtList, setDistrictList] = useState([]);
    const [districtShow, setDistrictShow] = useState(false);

    //setting error messages
    const [senderNameError, setsenderNameError] = useState("");
    const [senderPhoneNumberError, setSenderPhoneNumberError] = useState("");
    const [senderDobError, setSenderDobError] = useState("");
    const [receipentNameError, setReceipentNameError] = useState("");
    const [relationError, setRelationError] = useState("");
    const [receipentWhatsappNoError, setReceipentWhatsappNoError] =
        useState("");
    const [receipentContactNoError, setReceipentContactNoError] = useState("");
    const [receipentDobError, setReceipentDobError] = useState("");
    const [houseNameError, setHouseNameError] = useState("");
    const [streetNameError, setStreetNameError] = useState("");
    const [townNameError, setTownNameError] = useState("");
    const [pinCodeError, setPinCodeError] = useState("");
    const [districtError, setDistrictError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    //calender states
    const [isShow, setShow] = useState(false);
    const [classDate, setClassDate] = useState(false);
    const [date, setDate] = useState("");
    const [receipentDate, setReceipentDate] = useState("");
    const [senderDob, setSenderDob] = useState("");
    const [maxDate] = useState(new Date());

    //country modal states
    const [dropDown, setDropDown] = useState(false);
    const [searchCountry, setSearchCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [flag, setFlag] = useState(
        "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
    );
    const [phoneCode, setPhoneCode] = useState("+91");
    const [phoneNumberLength, setPhoneNumberLength] = useState(10);
    const [countryCode, setCountryCode] = useState("IN");

    // sender phone-number validation
    const [phoneValidation, setPhoneValidation] = useState(false);
    const [receipentPhoneValidation, setReceipentPhoneValidation] =
        useState(false);

    const phoneNumberValidation = (e) => {
        if (
            senderPhoneNumber?.length > phoneNumberLength ||
            senderPhoneNumber?.length < phoneNumberLength
        ) {
            setSenderPhoneNumberError(
                // "You must enter" +
                //     " " +
                //     phoneNumberLength +
                //     " " +
                //     "digit number"
                "Enter a valid phone number"
            );
            setPhoneValidation(true);
        } else {
            setPhoneValidation(false);
        }
        if (receipentContactNo?.length < 10) {
            setReceipentContactNoError("Enter a valid phone number");
            setReceipentPhoneValidation(true);
        } else {
            setReceipentPhoneValidation(false);
        }
    };

    //countries list api--------
    useEffect(() => {
        dettConfig
            .get(`api/v1/general/countries/?q=${searchCountry}`)
            .then((response) => {
                const { data } = response.data.data;
                setCountryList(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [searchCountry]);
    const [successModal, setSuccessModal] = useState(false);

    //preventing outside scroll
    useEffect(() => {
        if (successModal || dropDown) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [successModal, dropDown]);

    //mobile screen
    useEffect(() => {
        if (window.innerWidth <= 480) {
            setMobileScreen(true);
        }
    }, []);

    //outside click function
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setDistrictShow(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    // validating sender name
    const handleSenderNameChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setSenderName(value.trimStart());
        }
    };

    // vaidating receipient name
    const handleReceipientNameChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setReceipentName(value.trimStart());
        }
    };

    // validating relation search field
    const handleRelationChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setRelation(value.trimStart());
        }
    };

    //phone number condition check
    const handlePhoneNumberChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setSenderPhoneNumber(event.target.value);
        }
    };

    //whatsapp phone number condition check
    const handleWhatsappPhoneNumberChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setReceipentWhatsappNo(event.target.value);
        }
    };

    //Contact phone number condition check
    const handleContactPhoneNumberChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setReceipentContactNo(event.target.value);
        }
    };

    //Pin Code condition check
    const handleContactPinCodeChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
            setPinCode(event.target.value.slice(0, 6));
        }
    };

    // calendar handle function
    const handleCalendarlModal = () => {
        setShow(!isShow);
    };

    //date formatting for input mobile screen
    function DateFormatting(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB");
    }

    //setting the maximum date to today
    useEffect(() => {
        if (!isMobileScreen) {
            const today = new Date().toISOString().split("T")[0];
            if (date > today) {
                setDate(today);
            }
            if (receipentDate > today) {
                setReceipentDate(today);
            }
        }
    }, [date, receipentDate]);

    //checking the date format
    // if (date != "") {
    //     const inputDate = new Date(date);
    //     if (inputDate.toISOString() === date) {
    //       console.log('Input date is in ISO format.');
    //     } else {
    //       console.log('Input date is not in ISO format.');
    //     }
    // }

    //setting the date format for api
    const addDays = function (str, days) {
        const myDate = new Date(str);
        myDate.setDate(myDate.getDate() + parseInt(days));
        return myDate.toISOString();
    };

    const getYYMMDD = (value) => {
        if (value) {
            const event = new Date(value);
            const iso = event.toISOString();
            const d = addDays(iso, 1);
            const date = d.substring(0, 10);
            return date;
        }
        return "";
    };

    //clearing input fields
    const clearInputFields = () => {
        setSenderName("");
        setSenderPhoneNumber("");
        setAddress("");
        setReceipentName("");
        setRelation("");
        setReceipentWhatsappNo("");
        setReceipentContactNo("");
        setHouseName("");
        setStreetName("");
        setTownName("");
        setPinCode("");
        setLandMark("");
        setDistrict("");
        setDate("");
        setSenderDob("");
        setReceipentDate("");

        setSenderDobError("");
        setReceipentDobError("");
        setsenderNameError("");
        setSenderPhoneNumberError("");
        setReceipentNameError("");
        setRelationError("");
        setReceipentWhatsappNoError("");
        setReceipentContactNoError("");
        setHouseNameError("");
        setStreetNameError("");
        setTownNameError("");
        setPinCodeError("");
        setDistrictError("");
        setErrorMessage("");
        setRelationSuggestions(false);
    };

    //   Relation Api Connection---------
    useEffect(() => {
        dettConfig
            .get("api/v1/accounts/relations/", {
                params: {
                    q: relation,
                },
            })
            .then((response) => {
                const { data, StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setRelationList(data.data);
                    setrelationLoader(false);
                } else {
                    setrelationLoader(false);
                }
            })
            .catch((error) => {
                // setApiError(false);
            });
    }, [relation]);

    //relation (for highlighting searched letter)
    function renderRelationTitle() {
        return (
            <Group>
                {relationLoader ? (
                    <DropDownLoader />
                ) : (
                    relationList.length > 0 &&
                    relationList.map((item) => (
                        <Relation
                            key={item.id}
                            onClick={() => {
                                setRelation(item.title);
                                setRelationSuggestions(false);
                            }}
                        >
                            <Highlight
                                searchWords={[relation]}
                                textToHighlight={item.title}
                                highlightStyle={{
                                    backgroundColor: "rgba(0, 0, 0, 0)",
                                    fontWeight: "600",
                                }}
                            />
                        </Relation>
                    ))
                )}
            </Group>
        );
    }

    //fetching districts
    useEffect(() => {
        dettConfig
            .get("api/v1/general/districts/", {})
            .then((response) => {
                const { data, StatusCode } = response.data;
                if (StatusCode === 6000) {
                    setDistrictList(data.data);
                } else {
                }
            })
            .catch((error) => {});
    }, []);

    // Form submit Api ------------
    const formSubmit = async () => {
        await phoneNumberValidation();
        if (!phoneValidation && !receipentPhoneValidation) {
            dettConfig
                .post("api/v1/bookings/events/create/", {
                    // date: getYYMMDD(date),
                    date: "2023-03-08",
                    title: "Womens Day",
                    event: "womens_day",
                    is_recurring: false,
                    sender_name: senderName,
                    sender_phone: phoneCode + senderPhoneNumber,
                    sender_dob: date,
                    name: receipentName,
                    relation: relation,
                    phone: "+91" + receipentContactNo,
                    dob: receipentDate ? receipentDate : undefined,
                    house_name: houseName,
                    street: streetName,
                    city: townName,
                    pin_code: pinCode,
                    district: districtID,
                    sender_address: address,
                    landmark: landMark,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setSuccessModal(true);
                        clearInputFields();
                    } else if (StatusCode === 6001) {
                        setSuccessModal(false);
                        // setSenderDobError(
                        //     "Please check whether the DOB is in DD-MM-YYYY format"
                        // );
                        // setReceipentDobError(
                        //     "Please check whether the DOB is in DD-MM-YYYY format"
                        // );
                        if (senderName === "") {
                            setsenderNameError("This field is required");
                        }
                        if (senderPhoneNumber === "") {
                            setSenderPhoneNumberError("This field is required");
                        }
                        if (date === "") {
                            setSenderDobError("This field is required");
                        }
                        if (receipentName === "") {
                            setReceipentNameError("This field is required");
                        }
                        if (relation === "") {
                            setRelationError("This field is required");
                        }
                        if (receipentWhatsappNo === "") {
                            setReceipentWhatsappNoError(
                                "This field is required"
                            );
                        }
                        if (receipentContactNo === "") {
                            setReceipentContactNoError(
                                "This field is required"
                            );
                        }
                        // if (receipentDate === "") {
                        //     setReceipentDobError("This field is required");
                        // }
                        if (houseName === "") {
                            setHouseNameError("This field is required");
                        }
                        if (streetName === "") {
                            setStreetNameError("This field is required");
                        }
                        if (townName === "") {
                            setTownNameError("This field is required");
                        }
                        if (pinCode === "") {
                            setPinCodeError("This field is required");
                        }
                        if (district === "") {
                            setDistrictError("This field is required");
                        }
                        setErrorMessage("Please fill all the mandatory fields");

                        // if (data.title === "Failed") {
                        // }
                    }
                })
                .catch((error) => {
                    console.log("api error");
                });
        }
    };

    return (
        <>
            <DettLandingPageHeader />
            <SuccessModal
                successModal={successModal}
                setSuccessModal={setSuccessModal}
            />
            <Container>
                <Wrapper className="wrapper">
                    <CalendarModal
                        setClassDate={setClassDate}
                        setShow={setShow}
                        setDate={
                            senderDob === "sender" ? setDate : setReceipentDate
                        }
                        date={senderDob === "sender" ? date : receipentDate}
                        isShow={isShow}
                        maxDate={maxDate}
                        handleCalendarlModal={handleCalendarlModal}
                    />
                    <Content>
                        <Heading>Happy Women's Day</Heading>
                        <DetailsForm>
                            <SubHeadSection>
                                <SubHeading>SENDER</SubHeading>
                                <HRLine></HRLine>
                            </SubHeadSection>
                            <TopSection>
                                <InputContainer>
                                    <Title>Name*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter your Name"
                                            value={senderName}
                                            onChange={(e) => {
                                                handleSenderNameChange(e);
                                                setsenderNameError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {senderNameError && senderNameError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Whatsapp Number*</Title>
                                    <Bottom className="phone-bottom">
                                        <LeftBox
                                            onClick={() => setDropDown(true)}
                                        >
                                            <CountryIcon>
                                                <Icon src={flag} alt="flag" />
                                            </CountryIcon>

                                            <ArrowIcon>
                                                <Arrow src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/ArrowDown2.png" />
                                            </ArrowIcon>
                                        </LeftBox>
                                        <PhoneInput>
                                            {phoneCode} <span>|</span>
                                        </PhoneInput>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter your Number"
                                            value={senderPhoneNumber}
                                            onChange={(e) => {
                                                handlePhoneNumberChange(e);
                                                setSenderPhoneNumberError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Overlay
                                        type={dropDown}
                                        onClick={() => setDropDown(false)}
                                    >
                                        <CountryDropDown
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <SearchBox>
                                                <SearchInput
                                                    type="search"
                                                    placeholder="Search Country"
                                                    onChange={(e) =>
                                                        setSearchCountry(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={searchCountry}
                                                />
                                            </SearchBox>

                                            <CountryBox>
                                                {dropDown && (
                                                    <CountryInfoBox>
                                                        {countryList.map(
                                                            (item) => (
                                                                <CountryDetails
                                                                    onClick={() => {
                                                                        setFlag(
                                                                            item.flag
                                                                        );
                                                                        setPhoneCode(
                                                                            item.phone_code
                                                                        );
                                                                        setPhoneNumberLength(
                                                                            item.phone_number_length
                                                                        );
                                                                        setCountryCode(
                                                                            item.web_code
                                                                        );
                                                                        setSearchCountry(
                                                                            ""
                                                                        );
                                                                        setDropDown(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    <FlagBox>
                                                                        <Flag
                                                                            src={
                                                                                item.flag
                                                                            }
                                                                        />
                                                                    </FlagBox>
                                                                    <CountryName>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </CountryName>
                                                                    <PhoneCode>
                                                                        {
                                                                            item.phone_code
                                                                        }
                                                                    </PhoneCode>
                                                                </CountryDetails>
                                                            )
                                                        )}
                                                    </CountryInfoBox>
                                                )}
                                            </CountryBox>
                                        </CountryDropDown>
                                    </Overlay>
                                    <Error>
                                        {senderPhoneNumberError &&
                                            senderPhoneNumberError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Date of Birth*</Title>
                                    <Bottom className="date-bottom">
                                        <ContentInput
                                            type={
                                                isMobileScreen ? "text" : "date"
                                            }
                                            placeholder={"DD-MM-YYYY"}
                                            value={
                                                isMobileScreen
                                                    ? date
                                                        ? DateFormatting(date)
                                                        : date
                                                    : date
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            max={maxDate}
                                            onChange={(e) => {
                                                // setReversedDate(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'))
                                                setDate(e.target.value);
                                                setSenderDobError("");
                                            }}
                                        />
                                        {isMobileScreen && (
                                            <CalendarContainer
                                                onClick={() => {
                                                    handleCalendarlModal();
                                                    setSenderDob("sender");
                                                }}
                                            />
                                        )}
                                        <CalendarImgContainer
                                            onClick={() => {
                                                handleCalendarlModal();
                                                setSenderDob("sender");
                                            }}
                                        >
                                            <CalendarImg
                                                src={require("../../assets/images/Calendar.png")}
                                                alt="calendar"
                                            />
                                        </CalendarImgContainer>
                                    </Bottom>
                                    <Error>
                                        {senderDobError && senderDobError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Address</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter your Address"
                                            value={address}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                        />
                                    </Bottom>
                                    <Error></Error>
                                </InputContainer>
                            </TopSection>
                            <SubHeadSection>
                                <SubHeading>RECEIPIENT</SubHeading>
                                <HRLine></HRLine>
                            </SubHeadSection>
                            <BottomSection>
                                <InputContainer>
                                    <Title>Name*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Name"
                                            value={receipentName}
                                            onChange={(e) => {
                                                handleReceipientNameChange(e);
                                                setReceipentNameError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {receipentNameError &&
                                            receipentNameError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Relation*</Title>
                                    <Bottom
                                        onClick={() => {
                                            setRelationSuggestions(
                                                !relationSuggestions
                                            );
                                        }}
                                    >
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Relation"
                                            value={relation}
                                            onChange={(e) => {
                                                handleRelationChange(e);
                                                setRelationSuggestions(true);
                                                setRelationError("");
                                            }}
                                        />
                                    </Bottom>
                                    <DropContainer>
                                        {relationSuggestions && (
                                            <DropBox type={relationList}>
                                                {renderRelationTitle()}
                                            </DropBox>
                                        )}
                                    </DropContainer>
                                    <Error>
                                        {relationError && relationError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Phone Number*</Title>
                                    <Bottom className="phone-bottom">
                                        <LeftBox>
                                            <CountryIcon>
                                                <Icon
                                                    src={
                                                        "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
                                                    }
                                                    alt="flag"
                                                />
                                            </CountryIcon>
                                        </LeftBox>
                                        <PhoneInput>
                                            +91 <span>|</span>
                                        </PhoneInput>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            value={receipentContactNo}
                                            onChange={(e) => {
                                                handleContactPhoneNumberChange(
                                                    e
                                                );
                                                setReceipentContactNoError("");
                                            }}
                                            maxLength={10}
                                        />
                                    </Bottom>
                                    <Error>
                                        {receipentContactNoError &&
                                            receipentContactNoError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Date of Birth</Title>
                                    <Bottom className="date-bottom">
                                        <ContentInput
                                            type={
                                                isMobileScreen ? "text" : "date"
                                            }
                                            placeholder={"DD-MM-YYYY"}
                                            value={
                                                isMobileScreen
                                                    ? receipentDate
                                                        ? DateFormatting(
                                                              receipentDate
                                                          )
                                                        : receipentDate
                                                    : receipentDate
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            max={maxDate}
                                            onChange={(e) => {
                                                setReceipentDate(
                                                    e.target.value
                                                );
                                                setReceipentDobError("");
                                            }}
                                        />
                                        {isMobileScreen && (
                                            <CalendarContainer
                                                onClick={() => {
                                                    handleCalendarlModal();
                                                    setSenderDob("receipent");
                                                }}
                                            />
                                        )}
                                        <CalendarImgContainer
                                            onClick={() => {
                                                handleCalendarlModal();
                                                setSenderDob("receipent");
                                            }}
                                        >
                                            <CalendarImg
                                                src={require("../../assets/images/Calendar.png")}
                                                alt="calendar"
                                            />
                                        </CalendarImgContainer>
                                    </Bottom>
                                    <Error>
                                        {receipentDobError && receipentDobError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>House Name*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter House Name"
                                            value={houseName}
                                            onChange={(e) => {
                                                setHouseName(e.target.value);
                                                setHouseNameError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {houseNameError && houseNameError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Street Name*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Street Name"
                                            value={streetName}
                                            onChange={(e) => {
                                                setStreetName(e.target.value);
                                                setStreetNameError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {streetNameError && streetNameError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Town/City*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Town/City"
                                            value={townName}
                                            onChange={(e) => {
                                                setTownName(e.target.value);
                                                setTownNameError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {townNameError && townNameError}
                                    </Error>
                                </InputContainer>
                                <InputContainer ref={wrapperRef}>
                                    <Title>District*</Title>
                                    <Bottom
                                        className="district-bottom"
                                        onClick={() => {
                                            setDistrictShow(!districtShow);
                                        }}
                                    >
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter District"
                                            value={district}
                                            onChange={(e) => {
                                                setDistrict(e.target.value);
                                                setDistrictError("");
                                            }}
                                            disabled
                                        />
                                        <ArrowIcon className="district-arrow">
                                            <Arrow src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/ArrowDown2.png" />
                                        </ArrowIcon>
                                    </Bottom>
                                    <DropContainer>
                                        {districtShow && (
                                            <DropDown>
                                                {districtList.map((dist) => (
                                                    <div
                                                        key={dist.id}
                                                        onClick={() => {
                                                            setDistrictID(
                                                                dist.id
                                                            );
                                                            setDistrict(
                                                                dist.name
                                                            );
                                                            setDistrictShow(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        {dist.name}
                                                    </div>
                                                ))}
                                            </DropDown>
                                        )}
                                    </DropContainer>
                                    <Error>
                                        {districtError && districtError}
                                    </Error>
                                </InputContainer>
                                <InputContainer>
                                    <Title>Pin Code*</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Pin Code"
                                            value={pinCode}
                                            onChange={(e) => {
                                                handleContactPinCodeChange(e);
                                                setPinCodeError("");
                                            }}
                                        />
                                    </Bottom>
                                    <Error>
                                        {pinCodeError && pinCodeError}
                                    </Error>
                                </InputContainer>

                                <InputContainer>
                                    <Title>Landmark</Title>
                                    <Bottom>
                                        <ContentInput
                                            type="text"
                                            placeholder="Enter Landmark"
                                            value={landMark}
                                            onChange={(e) =>
                                                setLandMark(e.target.value)
                                            }
                                        />
                                    </Bottom>
                                    <Error></Error>
                                </InputContainer>
                            </BottomSection>
                            <SubmitButton
                                onClick={() => {
                                    formSubmit();
                                }}
                            >
                                Submit
                            </SubmitButton>
                            <Error className="common-error">
                                {/* {errorMessage && errorMessage} */}
                            </Error>
                        </DetailsForm>
                        <TermsContent>
                            <TermsHeading>Terms and conditions:</TermsHeading>
                            <TermsParagraph>
                                <TermsBullets>
                                    <small>●</small> Exclusive offer only for
                                    the first 250 people who apply for the gift.
                                </TermsBullets>
                                <TermsBullets>
                                    <small>●</small> This offer is valid only
                                    till 28 February 2023.
                                    <br />
                                </TermsBullets>
                                <TermsBullets>
                                    <small>●</small> Multiple orders from the
                                    same number are not promoted or considered.
                                    <br />
                                </TermsBullets>
                                <TermsBullets>
                                    <small>●</small> Delivery only inside
                                    Kerala.
                                    <br />
                                </TermsBullets>
                                <TermsBullets>
                                    <small>●</small> For other
                                    orders&nbsp;:-&nbsp;
                                    <DettLink
                                        href="https://www.dett.app/prebook"
                                        target="_blank"
                                    >
                                        dett.app
                                    </DettLink>
                                </TermsBullets>
                            </TermsParagraph>
                        </TermsContent>
                    </Content>
                </Wrapper>
            </Container>
        </>
    );
}

const Container = styled.section`
    background: linear-gradient(rgb(255, 248, 243) 0%, rgb(255, 246, 239) 100%);
    padding: 100px 0px 20px;
    min-height: 100vh;
    display: flex;
    align-items: center;
`;
const Wrapper = styled.section``;
const Content = styled.div`
    width: 80%;
    margin: 0 auto;
    background: #fff;
    padding: 25px 40px;
    border-radius: 12px;
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 360px) {
        padding: 25px;
    }
`;
const Heading = styled.h2`
    margin-bottom: 15px;
    color: #282c3f;
    font-size: 24px;
    font-family: "dm_sansboldbold";
`;
const DetailsForm = styled.form`
    margin-bottom: 20px;
`;
const SubHeadSection = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
`;
const SubHeading = styled.h4`
    margin-right: 10px;
    color: #ff5e48;
    font-family: "dm_sansboldbold";
    font-size: 18px;
`;
const HRLine = styled.hr`
    width: 100%;
    height: 2px;
    border: none;
    background-color: transparent;
    background-image: linear-gradient(to right, #ff5e48 33%, transparent 0%);
    background-position: 0 50%;
    background-size: 15px 1px;
`;
const TopSection = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
const InputContainer = styled.div``;
const Title = styled.label`
    font-family: "dm_sansboldmedium";
    font-size: 14px;
    text-transform: capitalize;
`;
const Bottom = styled.div`
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    &.date-bottom {
        padding: 13px;
        position: relative;
    }
    &.phone-bottom {
        padding: 10px;
    }
    &.district-bottom {
        cursor: pointer;
        input {
            cursor: pointer;
            color: #000;
        }
    }
`;
const CalendarContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
`;
const ContentInput = styled.input`
    width: 100%;
    &[type="date"] {
        text-transform: uppercase;
    }
`;
const Error = styled.p`
    font-size: 13px;
    color: #f00;
    display: flex;
    justify-content: end;
    &.common-error {
        width: 50%;
        justify-content: start;
        @media all and (max-width: 1080px) {
            width: 100%;
        }
    }
`;
const DropContainer = styled.div`
    position: relative;
`;
const DropDown = styled.div`
    position: absolute;
    /* min-width: 280px; */
    width: 100%;
    max-height: 190px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border: 1px solid #e0e0e0;
    transition: all 0.5s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
    background: #fff;
    z-index: 10;
    div {
        padding: 8px 30px;
        font-size: 16px;
        font-family: "dm_sansregular";
        cursor: pointer;
    }
`;
const BottomSection = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    @media all and (max-width: 980px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
const SubmitButton = styled.div`
    cursor: pointer;
    font-family: "dm_sansboldmedium";
    background: linear-gradient(rgb(255, 125, 108) 0%, rgb(255, 87, 64) 100%);
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 30px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const CalendarImgContainer = styled.div`
    width: 18px;
    cursor: pointer;
`;
const CalendarImg = styled.img``;
const TermsContent = styled.div``;
const TermsHeading = styled.h6`
    font-family: "dm_sansboldmedium";
    font-size: 16px;
`;
const TermsParagraph = styled.p`
    font-size: 14px;
`;
const TermsBullets = styled.span`
    display: flex;
    margin-top: 8px;
    small {
        display: inline-block;
        margin-right: 8px;
    }
`;
const DettLink = styled.a`
    color: #ff5e48;
`;
const LeftBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const CountryIcon = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
    @media all and (max-width: 360px) {
        width: 20px;
        height: 20px;
    }
`;
const Icon = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;
const ArrowIcon = styled.div`
    width: 12px;
    margin-right: 10px;
    &.district-arrow {
        margin: 0;
    }
    @media all and (max-width: 480px) {
        margin-right: 10px;
    }
    @media all and (max-width: 360px) {
        width: 12px;
    }
`;

const Arrow = styled.img`
    width: 100%;
    display: block;
`;
const PhoneInput = styled.h3`
    font-size: 14px;
    display: inline-block;
    display: flex;
    align-items: center;
    width: 45px;
    font-family: "dm_sansboldmedium";

    span {
        color: #9495a0;
        display: inline;
        margin: 0px 7px;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }

    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
    @media all and (max-width: 360px) {
        margin-right: 3px;
    }
`;

const Overlay = styled.section`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: fixed;
    inset: 0px;
    background: rgba(0, 0, 0, 0.5);
    opacity: ${({ type }) => (type ? "1" : "0")};
    z-index: ${({ type }) => (type ? "1000" : "-1")};
`;
const CountryDropDown = styled.div`
    padding: 18px 15px;
    width: 100%;
    transition: 0.5s ease;
    background: #fffcf7;
    border: 1px solid #e8e8e8;
    position: fixed;
    max-width: 320px;
    min-width: 320px;
    max-height: 450px;
    min-height: 450px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 480px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
const SearchBox = styled.form`
    margin-bottom: 5px;
    border: 2px solid #f2efe9;
    padding: 10px;
    width: 100%;

    @media all and (max-width: 1280px) {
        background-size: 15px;
    }

    @media all and (max-width: 768px) {
        background-size: 12px;
        background-position: center left 7px;
    }

    @media all and (max-width: 640px) {
        background-size: 14px;
        background-position: center left 25px;
    }
`;

const SearchInput = styled.input`
    font-size: 14px;
    color: #000;
    font-family: "dm_sansregular";
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Search.svg")
        no-repeat;
    background-size: 16px;
    border-radius: 8px;
    background-position: center left 0px;
    width: 100%;
    padding-left: 25px;

    @media all and (max-width: 1160px) {
        font-size: 12px;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;
const CountryBox = styled.div`
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        background: #747474;
    }
`;

const CountryInfoBox = styled.div``;
const CountryDetails = styled.li`
    display: flex;
    :hover {
        background: #fff7ea;
    }
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

const FlagBox = styled.div`
    width: 24px;
    height: 24px;

    @media all and (max-width: 1160px) {
        width: 22px;
        height: 22px;
    }

    @media all and (max-width: 980px) {
        margin-right: 5px;
    }
`;

const Flag = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const CountryName = styled.h2`
    font-size: 17px;
    font-family: "dm_sansregular";
    width: 120px;
    text-align: left;

    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const PhoneCode = styled.h5`
    font-size: 17px;
    font-family: "dm_sansregular";
    width: 45px;
    text-align: left;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const DropBox = styled.div`
    width: 100%;
    max-height: 176px;
    position: absolute;
    /* top: 78px; */
    z-index: 100;
    background: #fffcf7;
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px 20px;
    gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    &.occasion-drop {
        bottom: 50px;
        top: auto;
        padding: 10px 20px;
    }
    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        background: #747474;
    }
`;
const Group = styled.ul`
    width: 100%;
`;

const Relation = styled.div`
    padding: 8px;
    font-family: "dm_sansboldmedium";
    font-size: 14px;
    width: 100%;
    color: #333333;

    cursor: pointer;
    :hover {
        background-color: #ffebe4;
        display: inline-block;
        border-radius: 5px;
    }
`;
