import React from "react";
import "./assets/css/Style.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./components/routing/routers/MainRouter";
import Store from "./components/Contexts/Store";

function App() {
  return (
    <>
    <Store>
      <Router>
        <MainRouter />
      </Router>
    </Store>
    </>
  );
}

export default App;
