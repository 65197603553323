import React from "react";
import styled from "styled-components";
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";

export default function TemporaryClosing() {
    return (
        <>
            <DettLandingPageHeader />
            <Container>
                <Wrapper className="wrapper">
                    <Content>
                        <ClosedLogoContainer>
                            <ClosedImg
                                src={require("../../assets/images/closed.png")}
                                alt="closed-img"
                            />
                        </ClosedLogoContainer>
                        <Top>
                            <Heading>
                                Special offers
                                <br /> are not available right now&nbsp;!
                            </Heading>
                            <SubHeading>Keep waiting.</SubHeading>
                        </Top>
                        <Bottom>
                            Secure your gifts in advance with pre-booking
                            on&nbsp;
                            <DettLink href="https://www.dett.app/prebook">
                                dett.app
                            </DettLink>
                        </Bottom>
                        <Footer>
                            <IconsSection>
                                <IconContainer
                                    target="_blank"
                                    href="https://www.facebook.com/dettworld?mibextid=ZbWKwL"
                                >
                                    <IconImg
                                        src={
                                            require("../../assets/images/facebook.svg")
                                                .default
                                        }
                                        alt="facebook"
                                    />
                                </IconContainer>
                                <IconContainer
                                    target="_blank"
                                    href="https://twitter.com/dettworld?t=d47SSDlwb7dIHOAhqXx0Dg&s=09"
                                >
                                    <IconImg
                                        src={
                                            require("../../assets/images/twitter.svg")
                                                .default
                                        }
                                        alt="twitter"
                                    />
                                </IconContainer>
                                <IconContainer
                                    target="_blank"
                                    href="https://www.linkedin.com/company/dettworld/"
                                >
                                    <IconImg
                                        src={
                                            require("../../assets/images/linkedin.svg")
                                                .default
                                        }
                                        alt="linkedin"
                                    />
                                </IconContainer>
                                <IconContainer
                                    target="_blank"
                                    href="https://instagram.com/dett_world?igshid=ZDdkNTZiNTM="
                                >
                                    <IconImg
                                        src={
                                            require("../../assets/images/instagram.svg")
                                                .default
                                        }
                                        alt="facebook"
                                    />
                                </IconContainer>
                                <IconContainer
                                    target="_blank"
                                    href="https://youtube.com/@dettmedia"
                                >
                                    <IconImg
                                        src={
                                            require("../../assets/images/youtube.svg")
                                                .default
                                        }
                                        alt="youtube"
                                    />
                                </IconContainer>
                            </IconsSection>
                            <BottomText>
                                @2022 Dett Pvt Ltd, Inc
                                <br />
                                Terms of Service | Privacy Policy
                            </BottomText>
                        </Footer>
                    </Content>
                </Wrapper>
            </Container>
        </>
    );
}

const Container = styled.section`
    padding: 100px 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    text-align: center;
`;
const Wrapper = styled.section``;
const Content = styled.div``;
const ClosedLogoContainer = styled.div`
    width: 200px;
    margin: 0 auto;
    @media all and (max-width: 640px) {
        width: 170px;
    }
`;
const ClosedImg = styled.img``;
const Top = styled.div`
    margin-top: 30px;
`;
const Heading = styled.h4`
    font-family: "dm_sansboldbold";
    font-size: 40px;
    @media all and (max-width: 1080px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
`;
const SubHeading = styled.h5`
    /* font-family: "dm_sansboldbold";
    font-size: 40px;
    @media all and (max-width: 1080px) {
        font-size: 30px;
    }
    @media all and (max-width: 980px) {
        font-size: 24px;
    }
    @media all and (max-width: 640px) {
        font-size: 18px;
    } */
    font-family: "dm_sansboldbold";
    font-size: 40px;
    @media all and (max-width: 1080px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
`;
const Bottom = styled.p`
    margin-top: 50px;
    font-family: "dm_sansboldmedium";
    font-size: 30px;
    @media all and (max-width: 1080px) {
        font-size: 30px;
    }
    @media all and (max-width: 980px) {
        font-size: 24px;
    }
    @media all and (max-width: 640px) {
        font-size: 18px;
        margin-top: 30px;
    }
`;
const DettLink = styled.a`
    color: #ff5e48;
`;
const Footer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-top: 10px;
`;
const IconsSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 0 auto;
    @media all and (max-width: 640px) {
        width: 180px;
    }
`;
const IconContainer = styled.a`
    display: inline-block;
    width: 25px;
    @media all and (max-width: 640px) {
        width: 22px;
    }
`;
const IconImg = styled.img``;
const BottomText = styled.p`
    margin-top: 10px;
`;
